import { setupSpectrumToastContainer } from '@workfront/mfe/plugins/spectrumToast'
import initializeAuth from './auth-bootstrapper.js'
import initializeLogger, {
  loadLoggerAndLogError,
} from './logger-bootstrapper.js'
import initializePerformanceMetrics from './performance-metrics-bootstrapper.js'
import initializeToggles from './toggles-bootstrapper.js'
import initializeTrackingScripts from './tracking-scripts/tracking-scripts.bootstrapper.js'
import initializeUnifiedShell from './unified-shell-bootstrapper.js'
import initializeWorkfrontInspector from './workfront-inspector-bootstrapper.js'

export default function bootstrapSPA() {
  performance.mark('nwe_mfe_config_start')

  // These promises will block singleSpa.start() from being called
  const blockingPromises = [
    initializePerformanceMetrics(),
    initializeAuth(),
    initializeToggles(),
    initializeUnifiedShell(),
  ]

  // these can complete whenever we don't need to block start for these
  // some examples:
  // logging performance metrics
  const nonBlockingPromises = [
    initializeLogger(),
    initializeWorkfrontInspector(),
    initializeTrackingScripts(),
    setupSpectrumToastContainer(),
  ]

  return promiseBootstrapper(blockingPromises, nonBlockingPromises)
}

export function promiseBootstrapper(blockingPromises, nonBlockingPromises) {
  let appFailure = false

  Promise.all(nonBlockingPromises)
    .then(() => {
      performance.mark('nwe_non_blocking_promises_complete')

      System.import('@wf-mfe/logger').then(({ logPerformanceMetric }) => {
        logPerformanceMetric('nwe_non_blocking_promises_complete')
      })
    })
    .catch((err) => {
      console.error('The non-blocking MFE bootstrap process has failed')
      if (appFailure) return // Error is already reported
      return asyncLogError(err, false)
    })

  return Promise.all(blockingPromises)
    .then(() => {
      performance.mark('nwe_blocking_promises_complete')
      // save this mark such that shell can see it
      performance.mark('rt_nwe_blocking_promises_complete')
    })
    .catch((err) => {
      console.error('The blocking MFE bootstrap process has failed')
      appFailure = true
      return asyncLogError(err)
    })

  function asyncLogError(err, rethrow = true) {
    // method exists to override default rethrow value to true instead of false
    return loadLoggerAndLogError(err, rethrow)
  }
}
